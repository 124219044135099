import {mapGetters} from "vuex";

export default {
    props: ['orderData'],
    data(){
        return{

        }
    },
    computed:{
        ...mapGetters({
        })
    },
    methods:{
        closePopup(){
            this.$emit('closePopup');
        },
    }
}